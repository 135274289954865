import "./nft-collection.scss";
import glVal from "../../../assets/global-value.json";

export const NftCollection = () => {

    return (
        <>
            <div className='py-5 d-flex align-items-center gap-3'>
                <img src={require('../../../assets/logo/logo_theyawks.png')} className='img-fluid logo-the-yawks' alt='The Yawks Logo'></img>
                <div>
                    <h1 className='fw-bold yc-gold-honey m-0'>The Yawks</h1>
                    <h2 className='fw-bold text-uppercase m-0'>Solana NFT Collection</h2>
                </div>
            </div>
            <h2 className='pb-5'>A sports-themed 10K The Yawks collection.<br/>Exclusive pieces that aim to give even more identity to the members of our community by including them in activities and events.<br/>A virtual pass that aims to be a bridge between the user and the product.</h2>
            <div className='w-75 p-3 box-desc-yawks'>
                <p className='fs-3 m-0'>The Yawks is a new vision of the sports world. A collection of 10,000 Yawks that builds a new, informed and irreverent community.</p>
            </div>
            <h3 className='py-3'>Follow The Yawks</h3>
            <div className='d-flex flex-sm-row flex-column'>
                <div className='col-sm-12 col-md-6'>
                    <div className='my-4'>
                        <a href={glVal.site_the_yawks} target="_blank" rel="noreferrer" className='btn-more-info-yawks px-5 py-4 text-uppercase fw-bold'>
                            Discover more
                        </a>
                    </div>
                    <div className='d-flex gap-3 py-3 align-items-center'>
                        <a href={glVal.twitter_the_yawks} target="_blank" rel="noreferrer">
                            <img className="icon-footer-social" src={require(`../../../assets/social/twitter_w.png`)} alt="Twitter logo" />
                        </a>
                        <a href={glVal.url_discord} target="_blank" rel="noreferrer">
                            <img className="icon-footer-social" src={require(`../../../assets/social/discord_w.png`)} alt="Discord logo" />
                        </a>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 d-flex align-items-center flex-wrap gap-3'>
                    <a href={glVal.link_coll_opensea} target="_blank" rel="noreferrer" className='p-3 box-logo-mp'>
                        <img src={require('../../../assets/marketplace/opensea-light.svg').default} className='logo-mp' alt='OpenSea Logo'></img>
                    </a>
                    <a href={glVal.link_coll_magiceden} target="_blank" rel="noreferrer" className='p-3 box-logo-mp'>
                        <img src={require('../../../assets/marketplace/ME_Full_White.svg').default} className='logo-mp' alt='Magic Eden Logo'></img>
                    </a>
                    <a href={glVal.link_coll_solanart} target="_blank" rel="noreferrer" className='p-3 box-logo-mp'>
                            <img src={require('../../../assets/marketplace/solanart_light.svg').default} className='logo-mp' alt='Solanart Logo'></img>
                    </a>
                    <a href={glVal.link_coll_hyperspace} target="_blank" rel="noreferrer" className='p-3 box-logo-mp'>
                        <img src={require('../../../assets/marketplace/hyperspace.svg').default} className='logo-mp' alt='Hyperspace Logo'></img>
                    </a>
                </div>
            </div>
        </>
    ); 
};

