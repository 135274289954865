import React, { useEffect, useState } from "react";
import './sponsorship.scss';
import glVal from "../../assets/global-value.json";

export const Sponsorship = () => {
    const [langu, setLangu] = useState('en');
    
  useEffect(() => {
    window.scrollTo(0, 0);
    let usrlang = navigator.language || navigator.userLanguage;
    let pathLangu = usrlang.split('-')[0];
    if(pathLangu === 'en' || pathLangu === 'it'){
        setLangu(pathLangu);
    } else {
        setLangu('en');
    }
  },[]);

    return (
        <>
        <nav className="navbar fixed-top d-block p-0 navbar-bg d-flex align-items-center justify-content-between">
            <ul id="yc-menu" className='d-flex p-3 align-items-center'>
                <li>
                    <a className="navbar-brand" href="/">
                        <img src={require('../../assets/logo/yawclub_icon.png')} alt='YawClub logo' className='logo-nav'></img>
                    </a>
                </li>
            </ul>
            <ul id="yc-menu" className='d-md-flex d-none p-3 align-items-center'>
                <li>
                    <a href={glVal.url_twitter} target="_blank" rel="noreferrer">
                        <img className="icon-footer-social" src={require(`../../assets/social/twitter_w.png`)} alt="Twitter logo" />
                    </a>
                </li>
                <li>
                    <a href={glVal.url_discord} target="_blank" rel="noreferrer">
                        <img className="icon-footer-social" src={require(`../../assets/social/discord_w.png`)} alt="Discord logo" />
                    </a>
                </li>
            </ul>
        </nav>

        <div className="pt-5">
            <div className="mt-5 p-3">
                <h1 className="fs-3 fw-bold yc-gold-honey">Sponsorship</h1>
                <h3 className="fs-1">Give visibility to your brand on the YawClub app.</h3>
                <div className="mt-5">
                    <a href='mailto:info@yawclub.com?subject=YawClub%20-%20Sponsorship' className='btn-more-info-yawks px-5 py-4 text-uppercase fw-bold'>Contact us</a>
                </div>
            </div>
        </div>

        <div className="section-home position-relative">
            <div className="row m-0">
                <div className="col-sm-12 col-md-5 col-sponsor p-0">
                <img src={require('../../assets/image/bg-sponsorship.jpg')} alt='F1 Track' className='h-100 w-100' style={{objectFit:'cover'}}></img>
                </div>
                <div className="col-sm-12 col-md-7 col-sponsor p-5">
                    <div className="p-5">
                        <h2 className="fs-1 fw-bold pb-3 yc-gold-honey">Bring visibility to your brand</h2>
                        <p className="fs-5">
                            YawClub is our Fantasy Gaming platform that has been on the market since 2019.
                            <br/><br/>
                            Within the platform we offer visibility spaces such as advertising and customization.
                            <br/><br/>
                            Take a look at our MediaKit and contact us.
                        </p>
                    </div>
                    <div className="m-auto" style={{width:'max-content'}}>
                        <a href={`https://yawclub.com/static/brand/YawClub_brand_presentation_${langu}.pdf`} target="_blank" rel="noreferrer" className='btn-more-info-yawks px-5 py-4 text-uppercase fw-bold'>Download Mediakit</a>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
  };