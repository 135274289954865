import React, { useState } from "react";
import './menu.scss';
import glVal from "../../assets/global-value.json";

export const Menu = () => {
    
    return (
        <nav className="navbar fixed-top d-block p-0 navbar-bg d-flex align-items-center justify-content-between">
            <ul id="yc-menu" className='d-flex p-3 align-items-center'>
                <li>
                    <a className="navbar-brand" href="#">
                        <img src={require('../../assets/logo/yawclub_icon.png')} alt='YawClub logo' className='logo-nav'></img>
                    </a>
                </li>
            </ul>
            <ul id="yc-menu" className='d-md-flex d-none p-3 align-items-center'>
                <li className="fw-bold">
                    <a href="#game">The game</a>
                </li>
                <li className="fw-bold">
                    <a href="#news">News</a>
                </li>
                <li className="fw-bold">
                    <a href="#nft">Nft collection</a>
                </li>
                <li>
                    <a href={glVal.url_instagram} target="_blank" rel="noreferrer">
                        <img className="icon-footer-social" src={require(`../../assets/social/instagram_w.png`)} alt="Instagram logo" />
                    </a>
                </li>
                <li>
                    <a href={glVal.url_tiktok} target="_blank" rel="noreferrer">
                        <img className="icon-footer-social" src={require(`../../assets/social/tiktok_w.png`)} alt="TikTok logo" />
                    </a>
                </li>
            </ul>
        </nav>
    )
};
