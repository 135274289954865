import "./news.scss";

export const News = () => {

    return (
        <>
            <h1 className='fw-bold pt-5 yc-gold-honey'>News</h1>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-6 ps-0'>
                        <h2>Stay up to date with what's happening in the world of motorsport and in our fantasy game.<br/><br/>An online blog with tips on who to field in the game, news and updates on Formula 1, F2, F3, Indicar and Formula E.</h2>
                        <ul className='p-0 py-3 m-0 list-news yc-gold-honey fs-4'>
                            <li>Latest News</li>
                            <li>Tips on the Fantasy Game</li>
                            <li>Market quotes</li>
                            <li>Top & Flop</li>
                            <li>Technical analysis</li>
                        </ul>
                        <div className='my-4'>
                            <a href="https://news.yawclub.com" target="_blank" rel="noreferrer" className='btn-more-info-yawks px-5 py-4 text-uppercase fw-bold'>
                                YawClub news
                            </a>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6 p-3'>
                        <img src={require('../../../assets/image/news-img.png')} className='hero-image img-fluid' alt='News'></img>
                    </div>
                </div>
            </div>
        </>
    ); 
};

