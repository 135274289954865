import React from "react";
import "./about.scss";
import glVal from "../../assets/global-value.json";

export const About = () => {
  return (
    <div className="p-3 px-5">
      <p className="fs-4 txt-about">
        <span className="yc-gold-honey">
          <b>The Real Submarine</b>
        </span>{" "}
        is a startup founded in 2022 with the goal of creating the first gaming
        platform entirely dedicated to fantasy gaming about Formula 1.
        <br />
        <br />
        Thanks to the technological support and development work of{" "}
        <a href={glVal.sydea_website_link} target="_blank" rel="noreferrer">
          <span className="yc-gold-honey"><b>Sydea</b></span>
        </a>
        , the <a href="#game"><b>YawClub</b></a> mobile app is released in September 2022, in
        parallel with <a href="#news"><b>YawClub News</b></a>, an online blog of motorsports-related
        news.
        <br />
        In January 2023, Sydea and The Real Submarine conceptualize and create{" "}
        <a href="#nft"><b>The Yawks</b></a>, the first sports-themed NFT collection linked to the
        YawClub community, launching it on the market in March 2023.
      </p>
      <div className="p-3 d-flex flex-sm-row flex-column align-items-center justify-content-center gap-5">
        <svg
          id="Livello_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1719.95 131.17"
          className="logo-about"
        >
          <path
            className="cls-1-trs"
            d="m302.22,8.6v12.03h-41.26v108.3h-12.03V20.63h-41.17v-12.03h94.47Z"
          />
          <path
            className="cls-1-trs"
            d="m394.36,75.73v53.21h-12.03v-50.8c0-4.7-1.15-8.97-3.44-12.81-2.29-3.84-5.36-6.9-9.2-9.2-3.84-2.29-8.11-3.44-12.81-3.44s-8.9,1.15-12.76,3.44c-3.87,2.29-6.95,5.36-9.24,9.2-2.29,3.84-3.44,8.11-3.44,12.81v50.8h-12.03V0h12.03v54.84c3.04-4.35,6.99-7.82,11.86-10.4,4.87-2.58,10.17-3.87,15.9-3.87,6.47,0,12.38,1.58,17.71,4.73,5.33,3.15,9.57,7.39,12.72,12.72,3.15,5.33,4.73,11.23,4.73,17.71Z"
          />
          <path
            className="cls-1-trs"
            d="m447.66,131.17c-7.91,0-15.11-2.03-21.62-6.1-6.5-4.07-11.69-9.53-15.56-16.37-3.87-6.85-5.8-14.43-5.8-22.74,0-6.3,1.12-12.19,3.35-17.66,2.23-5.47,5.31-10.29,9.24-14.44,3.92-4.15,8.49-7.41,13.71-9.76,5.21-2.35,10.77-3.52,16.68-3.52,6.36,0,12.22,1.26,17.58,3.78,5.36,2.52,9.97,6.05,13.84,10.57,3.87,4.53,6.79,9.83,8.77,15.9,1.98,6.07,2.79,12.64,2.45,19.68h-72.89c.69,5.44,2.42,10.31,5.2,14.61,2.78,4.3,6.33,7.69,10.66,10.19,4.33,2.49,9.12,3.77,14.4,3.83,5.73,0,10.9-1.49,15.52-4.47,4.61-2.98,8.32-7.08,11.13-12.29l12.21,2.84c-3.44,7.62-8.63,13.85-15.56,18.7-6.93,4.84-14.7,7.26-23.29,7.26Zm-30.6-50.37h61.11c-.4-5.33-2.02-10.21-4.86-14.66-2.84-4.44-6.49-7.99-10.96-10.66s-9.37-4-14.7-4-10.2,1.3-14.61,3.91c-4.41,2.61-8.02,6.13-10.83,10.57-2.81,4.44-4.53,9.38-5.16,14.83Z"
          />
          <path
            className="cls-1-trs"
            d="m547.1,128.93V8.6h40.83c6.42,0,12.21,1.56,17.36,4.68,5.16,3.12,9.25,7.34,12.29,12.64,3.04,5.3,4.56,11.25,4.56,17.84,0,5.9-1.2,11.26-3.61,16.07-2.41,4.81-5.69,8.8-9.84,11.95-4.16,3.15-8.84,5.24-14.05,6.27l29.4,50.89h-13.92l-28.97-49.94h-22v49.94h-12.03Zm12.03-61.97h27.76c4.24,0,8.12-1.05,11.65-3.14,3.52-2.09,6.33-4.93,8.42-8.51,2.09-3.58,3.14-7.64,3.14-12.16s-1.05-8.58-3.14-12.16c-2.09-3.58-4.9-6.42-8.42-8.51-3.52-2.09-7.41-3.14-11.65-3.14h-27.76v47.62Z"
          />
          <path
            className="cls-1-trs"
            d="m677.59,131.17c-7.91,0-15.11-2.03-21.62-6.1-6.5-4.07-11.69-9.53-15.56-16.37-3.87-6.85-5.8-14.43-5.8-22.74,0-6.3,1.12-12.19,3.35-17.66,2.23-5.47,5.31-10.29,9.24-14.44,3.92-4.15,8.49-7.41,13.71-9.76,5.21-2.35,10.77-3.52,16.68-3.52,6.36,0,12.22,1.26,17.58,3.78,5.36,2.52,9.97,6.05,13.84,10.57,3.87,4.53,6.79,9.83,8.77,15.9,1.98,6.07,2.79,12.64,2.45,19.68h-72.89c.69,5.44,2.42,10.31,5.2,14.61,2.78,4.3,6.33,7.69,10.66,10.19,4.33,2.49,9.12,3.77,14.4,3.83,5.73,0,10.9-1.49,15.52-4.47,4.61-2.98,8.32-7.08,11.13-12.29l12.21,2.84c-3.44,7.62-8.63,13.85-15.56,18.7-6.93,4.84-14.7,7.26-23.29,7.26Zm-30.6-50.37h61.11c-.4-5.33-2.02-10.21-4.86-14.66-2.84-4.44-6.49-7.99-10.96-10.66s-9.37-4-14.7-4-10.2,1.3-14.61,3.91c-4.41,2.61-8.02,6.13-10.83,10.57-2.81,4.44-4.53,9.38-5.16,14.83Z"
          />
          <path
            className="cls-1-trs"
            d="m804.71,42.98h12.03v85.96h-12.03l-.43-15.99c-2.81,5.44-6.73,9.84-11.78,13.19-5.04,3.35-11.06,5.03-18.05,5.03-6.3,0-12.21-1.19-17.71-3.57s-10.34-5.66-14.53-9.84c-4.18-4.18-7.45-9.03-9.8-14.53-2.35-5.5-3.52-11.4-3.52-17.71s1.16-12,3.48-17.45c2.32-5.44,5.53-10.23,9.63-14.35,4.1-4.13,8.85-7.35,14.27-9.67,5.42-2.32,11.22-3.48,17.41-3.48,7.16,0,13.41,1.71,18.74,5.11,5.33,3.41,9.57,7.86,12.72,13.37l-.43-16.07Zm-30.51,76.5c6.13,0,11.4-1.5,15.82-4.51,4.41-3.01,7.81-7.06,10.19-12.16,2.38-5.1,3.57-10.71,3.57-16.85s-1.2-11.99-3.61-17.06-5.82-9.1-10.23-12.08c-4.41-2.98-9.66-4.47-15.73-4.47s-11.62,1.5-16.63,4.51c-5.02,3.01-9,7.06-11.95,12.16-2.95,5.1-4.43,10.74-4.43,16.93s1.52,11.9,4.56,16.98c3.04,5.07,7.06,9.1,12.08,12.08,5.01,2.98,10.47,4.47,16.37,4.47Z"
          />
          <path className="cls-1-trs" d="m835.05,128.93V0h12.03v128.93h-12.03Z" />
          <path
            className="cls-1-trs"
            d="m951,131.17c-6.42,0-12.62-1-18.61-3.01-5.99-2.01-11.22-4.87-15.69-8.6-4.47-3.72-7.62-8.17-9.46-13.32l11.26-4.21c1.26,3.55,3.57,6.61,6.92,9.15,3.35,2.55,7.28,4.51,11.78,5.89,4.5,1.38,9.1,2.06,13.8,2.06,5.5,0,10.63-.92,15.39-2.75,4.76-1.83,8.6-4.4,11.52-7.69,2.92-3.29,4.38-7.18,4.38-11.65s-1.52-8.25-4.56-11.17c-3.04-2.92-6.95-5.23-11.73-6.92-4.79-1.69-9.79-2.99-15-3.91-8.2-1.43-15.54-3.38-22.05-5.85-6.5-2.46-11.65-5.94-15.43-10.44-3.78-4.5-5.67-10.44-5.67-17.84,0-6.93,2.01-12.95,6.02-18.05,4.01-5.1,9.3-9.05,15.86-11.86,6.56-2.81,13.65-4.21,21.27-4.21,6.25,0,12.35.98,18.31,2.92,5.96,1.95,11.22,4.79,15.77,8.51,4.56,3.73,7.84,8.28,9.84,13.67l-11.43,4.13c-1.26-3.61-3.57-6.69-6.92-9.24-3.35-2.55-7.26-4.5-11.73-5.85-4.47-1.35-9.08-2.02-13.84-2.02-5.44-.06-10.53.85-15.26,2.71-4.73,1.86-8.55,4.44-11.48,7.74-2.92,3.3-4.38,7.15-4.38,11.56,0,5.16,1.36,9.13,4.08,11.9,2.72,2.78,6.43,4.86,11.13,6.23,4.7,1.38,10,2.61,15.9,3.7,7.68,1.32,14.81,3.37,21.4,6.15,6.59,2.78,11.89,6.48,15.9,11.09,4.01,4.61,6.02,10.3,6.02,17.06s-2.01,12.88-6.02,18.01c-4.01,5.13-9.3,9.1-15.86,11.9-6.56,2.81-13.71,4.21-21.45,4.21Z"
          />
          <path
            className="cls-1-trs"
            d="m1009.54,96.01v-53.03h12.03v50.63c0,4.7,1.15,8.97,3.44,12.81,2.29,3.84,5.37,6.91,9.24,9.2,3.87,2.29,8.12,3.44,12.76,3.44s8.95-1.15,12.76-3.44c3.81-2.29,6.86-5.36,9.15-9.2,2.29-3.84,3.44-8.11,3.44-12.81v-50.63h12.03l.09,85.96h-12.03l-.09-12.03c-3.04,4.36-6.98,7.82-11.82,10.4-4.84,2.58-10.13,3.87-15.86,3.87-6.48,0-12.38-1.58-17.71-4.73-5.33-3.15-9.57-7.39-12.72-12.72-3.15-5.33-4.73-11.23-4.73-17.71Z"
          />
          <path
            className="cls-1-trs"
            d="m1145.43,40.57c8.37,0,15.97,2.03,22.82,6.1,6.85,4.07,12.31,9.53,16.37,16.37,4.07,6.85,6.1,14.46,6.1,22.82,0,6.25-1.18,12.11-3.52,17.58-2.35,5.47-5.6,10.29-9.76,14.44-4.16,4.16-8.97,7.41-14.44,9.76-5.47,2.35-11.33,3.52-17.58,3.52-7.11,0-13.24-1.69-18.39-5.07-5.16-3.38-9.23-7.79-12.21-13.24v16.07h-12.03V0h12.03v58.88c2.98-5.44,7.05-9.86,12.21-13.24,5.16-3.38,11.29-5.07,18.39-5.07Zm-.09,78.82c6.13,0,11.69-1.5,16.68-4.51,4.99-3.01,8.97-7.06,11.95-12.16,2.98-5.1,4.47-10.72,4.47-16.85s-1.52-11.99-4.56-17.06c-3.04-5.07-7.06-9.1-12.08-12.08-5.02-2.98-10.5-4.47-16.46-4.47s-11.33,1.52-15.77,4.56c-4.44,3.04-7.85,7.11-10.23,12.21-2.38,5.1-3.57,10.72-3.57,16.85s1.2,11.9,3.61,16.98c2.41,5.07,5.83,9.1,10.27,12.08,4.44,2.98,9.67,4.47,15.69,4.47Z"
          />
          <path
            className="cls-1-trs"
            d="m1205.34,128.93V42.98h12.03v11.86c3.09-4.35,7.05-7.82,11.86-10.4,4.81-2.58,10.09-3.87,15.82-3.87,6.47,0,12.41,1.59,17.79,4.77,5.39,3.18,9.6,7.41,12.64,12.68,3.04-5.27,7.22-9.5,12.55-12.68,5.33-3.18,11.23-4.77,17.71-4.77s12.38,1.58,17.71,4.73c5.33,3.15,9.57,7.39,12.72,12.72,3.15,5.33,4.73,11.23,4.73,17.71v53.21h-12.03v-50.54c0-4.58-1.12-8.82-3.35-12.72-2.23-3.9-5.23-7.03-8.98-9.41-3.75-2.38-7.92-3.57-12.51-3.57s-8.75,1.1-12.51,3.31c-3.75,2.21-6.75,5.24-8.98,9.11-2.23,3.87-3.35,8.29-3.35,13.28v50.54h-12.03v-50.54c0-4.99-1.12-9.41-3.35-13.28-2.23-3.87-5.24-6.9-9.03-9.11-3.78-2.21-7.97-3.31-12.55-3.31s-8.68,1.19-12.46,3.57c-3.78,2.38-6.79,5.52-9.03,9.41-2.23,3.9-3.35,8.14-3.35,12.72v50.54h-12.03Z"
          />
          <path
            className="cls-1-trs"
            d="m1427.02,42.98h12.03v85.96h-12.03l-.43-15.99c-2.81,5.44-6.73,9.84-11.78,13.19-5.04,3.35-11.06,5.03-18.05,5.03-6.3,0-12.21-1.19-17.71-3.57-5.5-2.38-10.34-5.66-14.53-9.84-4.18-4.18-7.45-9.03-9.8-14.53-2.35-5.5-3.52-11.4-3.52-17.71s1.16-12,3.48-17.45c2.32-5.44,5.53-10.23,9.63-14.35,4.1-4.13,8.85-7.35,14.27-9.67,5.42-2.32,11.22-3.48,17.41-3.48,7.16,0,13.41,1.71,18.74,5.11,5.33,3.41,9.57,7.86,12.72,13.37l-.43-16.07Zm-30.51,76.5c6.13,0,11.4-1.5,15.82-4.51,4.41-3.01,7.81-7.06,10.19-12.16,2.38-5.1,3.57-10.71,3.57-16.85s-1.2-11.99-3.61-17.06-5.82-9.1-10.23-12.08c-4.41-2.98-9.66-4.47-15.73-4.47s-11.62,1.5-16.63,4.51c-5.02,3.01-9,7.06-11.95,12.16-2.95,5.1-4.43,10.74-4.43,16.93s1.52,11.9,4.56,16.98c3.04,5.07,7.06,9.1,12.08,12.08,5.01,2.98,10.47,4.47,16.37,4.47Z"
          />
          <path
            className="cls-1-trs"
            d="m1456.25,128.93V42.98h12.03v11.86c3.04-4.35,6.99-7.82,11.86-10.4,4.87-2.58,10.17-3.87,15.9-3.87,3.32,0,6.53.43,9.63,1.29l-4.9,11.95c-2.41-.74-4.76-1.12-7.05-1.12-4.64,0-8.9,1.15-12.76,3.44-3.87,2.29-6.95,5.36-9.24,9.2-2.29,3.84-3.44,8.11-3.44,12.81v50.8h-12.03Z"
          />
          <path
            className="cls-1-trs"
            d="m1522.69,27.94c-1.95,0-3.6-.64-4.94-1.93-1.35-1.29-2.02-2.91-2.02-4.86s.67-3.57,2.02-4.86c1.35-1.29,2.99-1.93,4.94-1.93s3.51.64,4.86,1.93c1.35,1.29,2.02,2.91,2.02,4.86s-.66,3.57-1.98,4.86c-1.32,1.29-2.95,1.93-4.9,1.93Zm-6.1,15.04h12.03v85.96h-12.03V42.98Z"
          />
          <path
            className="cls-1-trs"
            d="m1623.95,75.73v53.21h-12.03v-50.8c0-4.7-1.15-8.97-3.44-12.81-2.29-3.84-5.36-6.9-9.2-9.2-3.84-2.29-8.11-3.44-12.81-3.44s-8.9,1.15-12.76,3.44c-3.87,2.29-6.95,5.36-9.24,9.2-2.29,3.84-3.44,8.11-3.44,12.81v50.8h-12.03V42.98h12.03v11.86c3.04-4.35,6.99-7.82,11.86-10.4,4.87-2.58,10.17-3.87,15.9-3.87,6.47,0,12.38,1.58,17.71,4.73,5.33,3.15,9.57,7.39,12.72,12.72,3.15,5.33,4.73,11.23,4.73,17.71Z"
          />
          <path
            className="cls-1-trs"
            d="m1677.24,131.17c-7.91,0-15.11-2.03-21.62-6.1-6.5-4.07-11.69-9.53-15.56-16.37-3.87-6.85-5.8-14.43-5.8-22.74,0-6.3,1.12-12.19,3.35-17.66,2.23-5.47,5.31-10.29,9.24-14.44,3.92-4.15,8.49-7.41,13.71-9.76,5.21-2.35,10.77-3.52,16.68-3.52,6.36,0,12.22,1.26,17.58,3.78,5.36,2.52,9.97,6.05,13.84,10.57,3.87,4.53,6.79,9.83,8.77,15.9,1.98,6.07,2.79,12.64,2.45,19.68h-72.89c.69,5.44,2.42,10.31,5.2,14.61,2.78,4.3,6.33,7.69,10.66,10.19,4.33,2.49,9.12,3.77,14.4,3.83,5.73,0,10.9-1.49,15.52-4.47,4.61-2.98,8.32-7.08,11.13-12.29l12.21,2.84c-3.44,7.62-8.63,13.85-15.56,18.7-6.93,4.84-14.7,7.26-23.29,7.26Zm-30.6-50.37h61.11c-.4-5.33-2.02-10.21-4.86-14.66-2.84-4.44-6.49-7.99-10.96-10.66s-9.37-4-14.7-4-10.2,1.3-14.61,3.91c-4.41,2.61-8.02,6.13-10.83,10.57-2.81,4.44-4.53,9.38-5.16,14.83Z"
          />
          <path
            className="cls-2-trs"
            d="m106.56,127.31c35.16,0,63.66-28.5,63.66-63.66S141.72,0,106.56,0s-63.66,28.5-63.66,63.66,28.5,63.66,63.66,63.66Zm0-115.89c28.85,0,52.23,23.38,52.23,52.23s-23.38,52.23-52.23,52.23-52.23-23.38-52.23-52.23S77.71,11.43,106.56,11.43Z"
          />
          <path
            className="cls-2-trs"
            d="m109.71,130.44c14.69-.68,28.13-6.1,38.85-14.77,0,0,0,0,.01-.01,9.45-7.43,16.75-17.48,20.79-29.06,2.61-7.15,4.04-14.88,4.04-22.94,0-3.59-.28-7.11-.83-10.55,2.45,6.39,3.8,13.34,3.8,20.59,0,31.74-25.73,57.47-57.47,57.47-3.13,0-6.2-.25-9.2-.73Z"
          />
          <path
            className="cls-2-trs"
            d="m58.03,63.66c0-26.8,21.73-48.53,48.53-48.53,3.59,0,7.09.39,10.46,1.13-30.86,1-55.58,26.33-55.58,57.44,0,3.36.29,6.65.84,9.85-2.73-6.07-4.25-12.8-4.25-19.89Z"
          />
          <path
            className="cls-2-trs"
            d="m30.07,87.48c0-7.7.77-14.4,1.92-17.92.65.17,1.33.26,2.04.26.57,0,1.12-.06,1.65-.17,1.13,3.54,1.89,10.2,1.89,17.83,0,11.34-1.68,20.53-3.74,20.53s-3.74-9.19-3.74-20.53Z"
          />
          <path
            className="cls-2-trs"
            d="m30.49,36.19c0-11.34,1.68-20.53,3.74-20.53s3.74,9.19,3.74,20.53c0,7.72-.78,14.44-1.92,17.94-.65-.17-1.33-.26-2.03-.26-.56,0-1.11.06-1.64.17-1.13-3.53-1.89-10.2-1.89-17.85Z"
          />
          <circle className="cls-2-trs" cx="34.02" cy="61.85" r="5.75" />
          <circle
            className="cls-2-trs"
            cx="7.06"
            cy="44.78"
            r="7.06"
            transform="translate(-29.6 18.11) rotate(-45)"
          />
          <path
            className="cls-2-trs"
            d="m0,60.72c0,2.81,2.28,5.09,5.09,5.09s5.09-2.28,5.09-5.09-2.28-5.09-5.09-5.09-5.09,2.28-5.09,5.09Z"
          />
          <path
            className="cls-2-trs"
            d="m15.67,67.08c0,2.07,1.68,3.75,3.76,3.75s3.76-1.68,3.76-3.75-1.68-3.76-3.76-3.76-3.76,1.68-3.76,3.76Z"
          />
          <path
            className="cls-2-trs"
            d="m14.02,56.7c0,1.19.96,2.15,2.15,2.15s2.15-.96,2.15-2.15-.96-2.15-2.15-2.15-2.15.96-2.15,2.15Z"
          />
        </svg>
        <a href={glVal.sydea_website_link} target="_blank" rel="noreferrer">
          <svg
            id="Livello_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 772 250.6"
            className="logo-about"
          >
            <path
              className="cls-1-syd"
              d="m119.7,249.8v-3.8c3.6.8,6.8,1.2,9.5,1.2,2.3,0,4-.3,5-.8,1.1-.5,1.6-1.5,1.6-2.9v-3.9c0-1.3-.4-2.2-1.1-2.8-.8-.5-2.1-.8-3.9-.8h-3.6c-3,0-5.1-.6-6.3-1.8-1.2-1.2-1.9-3-1.9-5.5v-2.4c0-1.7.4-3,1.1-4,.7-1,2-1.8,3.7-2.3s4.1-.7,7.1-.7c2,0,4.6.2,7.8.5v3.4c-3.6-.5-6.3-.8-8.1-.8-2.9,0-4.8.3-5.7.8-1,.6-1.4,1.6-1.4,2.9v3.5c0,1.1.4,1.8,1.1,2.3.8.5,2.1.7,3.9.7h3.6c2.1,0,3.7.2,4.9.7s2,1.3,2.5,2.3.7,2.4.7,4.1v2.2c0,2.1-.4,3.8-1.2,5.1-.8,1.2-2,2.1-3.7,2.7-1.7.5-3.8.8-6.5.8-2.4.1-5.5-.2-9.1-.7"
            />
            <polygon
              className="cls-1-syd"
              points="160.6 237.9 149.6 219.8 154.8 219.8 162.9 234 171 219.8 176.1 219.8 165.1 237.7 165.1 250.1 160.6 250.1 160.6 237.9"
            />
            <path
              className="cls-1-syd"
              d="m185.9,249.8v-3.8c3.6.8,6.8,1.2,9.5,1.2,2.3,0,4-.3,5-.8,1.1-.5,1.6-1.5,1.6-2.9v-3.9c0-1.3-.4-2.2-1.1-2.8s-2.1-.8-3.9-.8h-3.6c-3,0-5.1-.6-6.3-1.8-1.2-1.2-1.9-3-1.9-5.5v-2.4c0-1.7.4-3,1.1-4,.7-1,2-1.8,3.7-2.3s4.1-.7,7.1-.7c2,0,4.6.2,7.8.5v3.4c-3.6-.5-6.3-.8-8.1-.8-2.9,0-4.8.3-5.7.8-1,.6-1.5,1.6-1.5,2.9v3.5c0,1.1.4,1.8,1.1,2.3.8.5,2.1.7,3.9.7h3.6c2.1,0,3.7.2,4.9.7s2,1.3,2.5,2.3.7,2.4.7,4.1v2.2c0,2.1-.4,3.8-1.2,5.1-.8,1.2-2,2.1-3.7,2.7-1.7.5-3.8.8-6.5.8-2.4.1-5.5-.2-9-.7"
            />
            <polygon
              className="cls-1-syd"
              points="226.4 223 216.1 223 216.1 219.8 241.2 219.8 241.2 223 231 223 231 250.1 226.4 250.1 226.4 223"
            />
            <polygon
              className="cls-1-syd"
              points="250.4 219.8 270.8 219.8 270.8 223 254.9 223 254.9 232.9 269.2 232.9 269.2 236 254.9 236 254.9 246.9 271.2 246.9 271.2 250.1 250.4 250.1 250.4 219.8"
            />
            <polygon
              className="cls-1-syd"
              points="281 219.8 287.8 219.8 297.5 245 297.6 245.1 306.8 219.8 313.4 219.8 313.4 250.1 309.4 250.1 309.4 224.4 309.2 224.4 299.4 250.1 295.3 250.1 285.3 224.4 285 224.4 285 250.1 281 250.1 281 219.8"
            />
            <rect
              className="cls-1-syd"
              x="347.1"
              y="219.8"
              width="4.5"
              height="30.3"
            />
            <polygon
              className="cls-1-syd"
              points="360.9 219.8 366.4 219.8 382.9 244.8 382.9 219.8 386.8 219.8 386.8 250.1 381.4 250.1 364.9 224.6 364.9 250.1 360.9 250.1 360.9 219.8"
            />
            <polygon
              className="cls-1-syd"
              points="406.4 223 396.1 223 396.1 219.8 421.2 219.8 421.2 223 411 223 411 250.1 406.4 250.1 406.4 223"
            />
            <polygon
              className="cls-1-syd"
              points="430.5 219.8 450.9 219.8 450.9 223 435.1 223 435.1 232.9 449.4 232.9 449.4 236 435.1 236 435.1 246.9 451.4 246.9 451.4 250.1 430.5 250.1 430.5 219.8"
            />
            <path
              className="cls-1-syd"
              d="m470.8,250.4c-1.1-.1-2.3-.4-3.5-.7-1.4-.4-2.5-.8-3.5-1.4-.9-.6-1.7-1.4-2.3-2.4-.6-1.1-.9-2.2-.9-3.6v-14.3c0-1.7.5-3.2,1.4-4.5.9-1.2,2.2-2.2,3.7-2.7s3-.9,4.4-1.1,2.9-.3,4.5-.3c3.9,0,7.4.2,10.5.7v3.5c-1.2-.3-2.9-.5-5.1-.8-2.2-.2-4-.4-5.3-.4-6.4,0-9.6,1.8-9.6,5.5v14.4c0,1,.3,1.9.9,2.6s1.4,1.3,2.5,1.6c1,.4,2,.6,3,.8,1,.1,2.1.2,3.4.2,2.1,0,4.3-.3,6.7-.8v-10.6h-7.1v-3.2h11.3v16.3c-4.1.9-7.8,1.4-11.2,1.4-1.4,0-2.7-.1-3.8-.2"
            />
            <path
              className="cls-1-syd"
              d="m497,219.8h10.7c2.7,0,4.8.2,6.3.5s2.6.9,3.3,1.8c.7.8,1.1,2.1,1.1,3.7v5.2c0,1.8-.7,3.2-2.2,4.2s-3.4,1.5-5.6,1.5l9.7,13.5h-5.6l-8.7-12.9h-4.4v12.9h-4.6v-30.4Zm10.5,14.4c2.2,0,3.9-.3,4.8-.8,1-.5,1.5-1.5,1.5-3v-4.2c0-2.3-2-3.4-5.9-3.4h-6.4v11.4h6Z"
            />
            <path
              className="cls-1-syd"
              d="m539.6,219.8h5.2l11.8,30.3h-4.7l-3.5-9.2h-13l-3.4,9.2h-4.5l12.1-30.3Zm7.7,18l-5.1-13.7h-.4l-5.1,13.7h10.6Z"
            />
            <polygon
              className="cls-1-syd"
              points="572.3 223 562 223 562 219.8 587.1 219.8 587.1 223 576.8 223 576.8 250.1 572.3 250.1 572.3 223"
            />
            <path
              className="cls-1-syd"
              d="m596.4,242.3v-14.3c0-5.8,4.2-8.7,12.7-8.7s12.8,2.9,12.8,8.6v14.4c0,2.9-1.2,5-3.5,6.3-2.4,1.3-5.5,1.9-9.3,1.9-8.5.1-12.7-2.7-12.7-8.2m21.1,0v-14.4c0-3.7-2.8-5.6-8.4-5.6-2.7,0-4.8.4-6.2,1.3-1.4.8-2.2,2.3-2.2,4.3v14.4c0,3.5,2.8,5.2,8.5,5.2s8.3-1.7,8.3-5.2"
            />
            <path
              className="cls-1-syd"
              d="m631.2,219.8h10.7c2.7,0,4.8.2,6.3.5s2.6.9,3.3,1.8c.7.8,1.1,2.1,1.1,3.7v5.2c0,1.8-.7,3.2-2.2,4.2s-3.4,1.5-5.6,1.5l9.7,13.5h-5.6l-8.7-12.9h-4.4v12.9h-4.5v-30.4h-.1Zm10.5,14.4c2.2,0,3.9-.3,4.8-.8,1-.5,1.5-1.5,1.5-3v-4.2c0-2.3-2-3.4-5.9-3.4h-6.4v11.4h6Z"
            />
            <path
              className="cls-1-syd"
              d="m451.1,57v76.3c0,14.2-2.8,25.3-8.4,33.1-5.6,7.8-14.8,13.3-27.5,16.5s-30.3,4.8-52.9,4.8h-88.6v-68.8l43.6-66.5v103.2h43c12.3,0,21.7-.5,27.9-1.6,6.3-1.1,10.8-3.1,13.6-6.2,2.8-3.1,4.2-7.9,4.2-14.5V56.1c0-8.9-3.5-15.1-10.5-18.7-6.6-3.4-16.9-5.1-30.8-5.3h-.8l-71.2.1-48.8,79.7v75.8h-45.1v-75.2l-48.8-80.7c-31.3.1-68.8.4-76.7.4-15.2,0,1.5.1-7.4.1-12.2,0-20.7,10-20.7,15.8v10.5c0,9.8,8.3,14.8,24.9,14.8h38.1c21.4,0,36.9,3.9,46.5,11.9,9.6,7.9,14.5,19.9,14.5,36.1v12.4c0,22.4-4.2,35.9-16.2,44.6-10.8,7.7-26.4,9.2-36.9,9.2-9.5,0-14.3.7-28,.5-16.5-.1-56.9.3-88-.1v-30c31.9,0,77.5.1,89.5.1,21.6,0,34.5.8,34.5-19.4v-12.1c0-6.2-1.8-10.9-5.4-14.2-3.6-3.3-10-4.9-19.2-4.9h-37.6C20.6,107.5,0,91.1,0,58.3v-13.9C0,28,6.8,16,20.5,8.3,34.2.6,34.8.3,64.5.3h66.4l-.1-.1h51.2l39.6,73.4L260.9.2h101.2c6.2,0,12,.1,17.5.4,13.9.7,25.4,2.2,34.6,4.6,12.7,3.4,22.1,9.2,28,17.5,5.9,8.2,8.9,19.6,8.9,34.3Z"
            />
            <polygon
              className="cls-1-syd"
              points="573.8 150.1 573.8 187.7 463.7 187.7 463.7 123.1 501.5 123.1 501.5 150.1 573.8 150.1"
            />
            <path
              className="cls-1-syd"
              d="m598.1,123.1l-23.9,64.5h44.5l21.6-64.5h-42.2Zm144.8,0h-44l21.6,64.5h45.7l-23.3-64.5ZM698.5,0h-54.9l-33.6,91h41.2l17.9-53.4h1.1l17.9,53.4h43.3L698.5,0Z"
            />
            <polygon
              className="cls-1-syd"
              points="574.2 0 574.2 37.6 501.5 37.6 501.5 91 463.7 91 463.7 0 574.2 0"
            />
            <polygon
              className="cls-2-syd"
              points="501.5 123.1 501.5 91 573.9 91 573.9 48.9 598.2 123.1 501.5 123.1"
            />
            <polygon
              className="cls-2-syd"
              points="772 123.1 640.4 123.1 651.2 91 760.4 91 772 123.1"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};
