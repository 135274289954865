import React from "react";
import './footer.scss';
import glVal from "../../assets/global-value.json";
import { Link } from "react-router-dom";

const currentYear = new Date().getFullYear();

export const Footer = () => {
    return (
        <footer className="footer p-3">
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-12 py-3 text-center'>
                        <img className="img-fluid icon-footer" src={require(`../../assets/logo/yawclub_h_w.png`)} alt="YawClub logo" />
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <p className="fs-3 m-0"><b>The Real Submarine S.r.l.</b></p>
                        <p className="m-0">Piazza Malpighi 7, 40123 Bologna (BO) - VAT 04040921209</p>
                        <p className='m-0'>&copy; <span>The Real Submarine S.r.l. {currentYear}</span> | All rights reserved</p>
                        {/* <br/> */}
                        {/* <a href= "mailto:info@yawclub.com" className="yc-gold-honey mail-info-footer">info@yawclub.com</a> */}
                    </div>
                    <div className='col-sm-12 col-md-6 text-end'>
                        <p className="fs-4"><b>Follow us</b></p>
                        <div className="d-flex justify-content-center justify-content-sm-end align-items-center gap-3">
                            <a href={glVal.url_instagram} target="_blank" rel="noreferrer">
                                <img className="icon-footer-social" src={require(`../../assets/social/instagram_w.png`)} alt="Instagram logo" />
                            </a>
                            <a href={glVal.url_facebook} target="_blank" rel="noreferrer">
                                <img className="icon-footer-social" src={require(`../../assets/social/facebook_w.png`)} alt="Facebook logo" />
                            </a>
                            <a href={glVal.url_tiktok} target="_blank" rel="noreferrer">
                                <img className="icon-footer-social" src={require(`../../assets/social/tiktok_w.png`)} alt="TikTok logo" />
                            </a>
                            <a href={glVal.url_twitter} target="_blank" rel="noreferrer">
                                <img className="icon-footer-social" src={require(`../../assets/social/twitter_w.png`)} alt="Twitter logo" />
                            </a>
                            <a href={glVal.url_discord} target="_blank" rel="noreferrer">
                                <img className="icon-footer-social" src={require(`../../assets/social/discord_w.png`)} alt="Discord logo" />
                            </a>
                            <a href={glVal.url_twitch} target="_blank" rel="noreferrer">
                                <img className="icon-footer-social" src={require(`../../assets/social/twitch.png`)} alt="Twitch logo" />
                            </a>
                        </div>
                    </div>

                    <div id="nav-footer" className="col-12 d-flex flex-column flex-sm-row justify-content-between pt-4 gap-1 ">
                        <ul className="p-0 m-0 d-flex flex-column flex-sm-row gap-1 gap-sm-5">
                            <li>
                                <a href="https://yawclub.com/static/docs/policy_privacy.html" target="_blank">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="https://yawclub.com/static/docs/terms_and_conditions.html" target="_blank">
                                    Terms and Conditions
                                </a>
                            </li>
                            <li>
                                <Link to='profile'>
                                    Private area
                                </Link>
                            </li>
                            <li>
                                <Link to='sponsorship'>
                                    Sponsorship
                                </Link>
                            </li>
                        </ul>
                        <a href= "mailto:info@yawclub.com" className="">info@yawclub.com</a>
                    </div>

                    <div className='col-12 d-flex flex-sm-row flex-column justify-content-center align-items-center gap-3 pt-5'>
                        <a href='https://play.google.com/store/apps/details?id=com.sydea.fantaf1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style={{display:'inline-block', overflow:'hidden', height:'83px'}} target="_blank" rel="noreferrer">
                            <img src={require(`../../assets/social/googleplay.png`)} alt="Get it on Google Play" style={{ height:'83px' }} />
                        </a>
                        <a href="https://apps.apple.com/us/app/yawclub/id1633101059?itsct=apps_box_badge&amp;itscg=30200" style={{display:'inline-block', overflow:'hidden',  borderRadius:'13px', width:'250px', height:'83px' }} target="_blank" rel="noreferrer">
                            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1662681600" alt="Download on the App Store" style={{ borderRadius:'13px', width:'250px', height:'83px' }} />
                        </a>
                    </div>
                </div>

                <div className="box-sydea-logo pt-5 m-auto">
                    <a href={glVal.sydea_website_link} target="_blank" rel="noreferrer">
                    <svg
                    id="Livello_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1018.18 505.01"
                    >
                    <path
                        className="cls-1-sydea"
                        d="m157.87,503.95v-5.01c4.75,1.06,8.97,1.58,12.53,1.58,3.03,0,5.28-.4,6.59-1.06,1.45-.66,2.11-1.98,2.11-3.82v-5.14c0-1.71-.53-2.9-1.45-3.69-1.06-.66-2.77-1.06-5.14-1.06h-4.75c-3.96,0-6.73-.79-8.31-2.37-1.58-1.58-2.51-3.96-2.51-7.25v-3.17c0-2.24.53-3.96,1.45-5.28.92-1.32,2.64-2.37,4.88-3.03,2.24-.66,5.41-.92,9.36-.92,2.64,0,6.07.26,10.29.66v4.48c-4.75-.66-8.31-1.06-10.68-1.06-3.82,0-6.33.4-7.52,1.06-1.32.79-1.85,2.11-1.85,3.82v4.62c0,1.45.53,2.37,1.45,3.03,1.06.66,2.77.92,5.14.92h4.75c2.77,0,4.88.26,6.46.92,1.58.66,2.64,1.71,3.3,3.03.66,1.32.92,3.17.92,5.41v2.9c0,2.77-.53,5.01-1.58,6.73-1.06,1.58-2.64,2.77-4.88,3.56-2.24.66-5.01,1.06-8.57,1.06-3.17.13-7.25-.26-12-.92"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="211.81 488.26 197.31 464.38 204.16 464.38 214.85 483.11 225.53 464.38 232.26 464.38 217.75 487.99 217.75 504.35 211.81 504.35 211.81 488.26"
                    />
                    <path
                        className="cls-1-sydea"
                        d="m245.18,503.95v-5.01c4.75,1.06,8.97,1.58,12.53,1.58,3.03,0,5.28-.4,6.59-1.06,1.45-.66,2.11-1.98,2.11-3.82v-5.14c0-1.71-.53-2.9-1.45-3.69-.92-.79-2.77-1.06-5.14-1.06h-4.75c-3.96,0-6.73-.79-8.31-2.37-1.58-1.58-2.51-3.96-2.51-7.25v-3.17c0-2.24.53-3.96,1.45-5.28.92-1.32,2.64-2.37,4.88-3.03,2.24-.66,5.41-.92,9.36-.92,2.64,0,6.07.26,10.29.66v4.48c-4.75-.66-8.31-1.06-10.68-1.06-3.82,0-6.33.4-7.52,1.06-1.32.79-1.98,2.11-1.98,3.82v4.62c0,1.45.53,2.37,1.45,3.03,1.06.66,2.77.92,5.14.92h4.75c2.77,0,4.88.26,6.46.92,1.58.66,2.64,1.71,3.3,3.03.66,1.32.92,3.17.92,5.41v2.9c0,2.77-.53,5.01-1.58,6.73-1.06,1.58-2.64,2.77-4.88,3.56-2.24.66-5.01,1.06-8.57,1.06-3.17.13-7.25-.26-11.87-.92"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="298.6 468.61 285.01 468.61 285.01 464.38 318.12 464.38 318.12 468.61 304.66 468.61 304.66 504.35 298.6 504.35 298.6 468.61"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="330.25 464.38 357.15 464.38 357.15 468.61 336.18 468.61 336.18 481.66 355.04 481.66 355.04 485.75 336.18 485.75 336.18 500.13 357.68 500.13 357.68 504.35 330.25 504.35 330.25 464.38"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="370.61 464.38 379.58 464.38 392.37 497.62 392.5 497.75 404.63 464.38 413.34 464.38 413.34 504.35 408.06 504.35 408.06 470.45 407.8 470.45 394.88 504.35 389.47 504.35 376.28 470.45 375.88 470.45 375.88 504.35 370.61 504.35 370.61 464.38"
                    />
                    <rect className="cls-1-sydea" x="457.79" y="464.38" width="5.93" height="39.96" />
                    <polygon
                        className="cls-1-sydea"
                        points="475.99 464.38 483.24 464.38 505 497.36 505 464.38 510.15 464.38 510.15 504.35 503.02 504.35 481.26 470.72 481.26 504.35 475.99 504.35 475.99 464.38"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="536 468.61 522.41 468.61 522.41 464.38 555.52 464.38 555.52 468.61 542.06 468.61 542.06 504.35 536 504.35 536 468.61"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="567.78 464.38 594.69 464.38 594.69 468.61 573.85 468.61 573.85 481.66 592.71 481.66 592.71 485.75 573.85 485.75 573.85 500.13 595.35 500.13 595.35 504.35 567.78 504.35 567.78 464.38"
                    />
                    <path
                        className="cls-1-sydea"
                        d="m620.93,504.74c-1.45-.13-3.03-.53-4.62-.92-1.85-.53-3.3-1.06-4.62-1.85-1.19-.79-2.24-1.85-3.03-3.17-.79-1.45-1.19-2.9-1.19-4.75v-18.86c0-2.24.66-4.22,1.85-5.93,1.19-1.58,2.9-2.9,4.88-3.56,1.98-.66,3.96-1.19,5.8-1.45,1.85-.26,3.82-.4,5.93-.4,5.14,0,9.76.26,13.85.92v4.62c-1.58-.4-3.82-.66-6.73-1.06-2.9-.26-5.28-.53-6.99-.53-8.44,0-12.66,2.37-12.66,7.25v18.99c0,1.32.4,2.51,1.19,3.43.79.92,1.85,1.71,3.3,2.11,1.32.53,2.64.79,3.96,1.06,1.32.13,2.77.26,4.48.26,2.77,0,5.67-.4,8.84-1.06v-13.98h-9.36v-4.22h14.9v21.5c-5.41,1.19-10.29,1.85-14.77,1.85-1.85,0-3.56-.13-5.01-.26"
                    />
                    <path
                        className="cls-1-sydea"
                        d="m655.49,464.38h14.11c3.56,0,6.33.26,8.31.66,1.98.4,3.43,1.19,4.35,2.37.92,1.06,1.45,2.77,1.45,4.88v6.86c0,2.37-.92,4.22-2.9,5.54-1.98,1.32-4.48,1.98-7.39,1.98l12.79,17.8h-7.39l-11.47-17.01h-5.8v17.01h-6.07v-40.09Zm13.85,18.99c2.9,0,5.14-.4,6.33-1.06,1.32-.66,1.98-1.98,1.98-3.96v-5.54c0-3.03-2.64-4.48-7.78-4.48h-8.44v15.04h7.91Z"
                    />
                    <path
                        className="cls-1-sydea"
                        d="m711.67,464.38h6.86l15.56,39.96h-6.2l-4.62-12.13h-17.15l-4.48,12.13h-5.93l15.96-39.96Zm10.16,23.74l-6.73-18.07h-.53l-6.73,18.07h13.98Z"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="754.8 468.61 741.22 468.61 741.22 464.38 774.32 464.38 774.32 468.61 760.73 468.61 760.73 504.35 754.8 504.35 754.8 468.61"
                    />
                    <path
                        className="cls-1-sydea"
                        d="m786.59,494.06v-18.86c0-7.65,5.54-11.47,16.75-11.47s16.88,3.82,16.88,11.34v18.99c0,3.82-1.58,6.59-4.62,8.31-3.17,1.71-7.25,2.51-12.27,2.51-11.21.13-16.75-3.56-16.75-10.81m27.83,0v-18.99c0-4.88-3.69-7.39-11.08-7.39-3.56,0-6.33.53-8.18,1.71-1.85,1.06-2.9,3.03-2.9,5.67v18.99c0,4.62,3.69,6.86,11.21,6.86s10.95-2.24,10.95-6.86"
                    />
                    <path
                        className="cls-1-sydea"
                        d="m832.48,464.38h14.11c3.56,0,6.33.26,8.31.66,1.98.4,3.43,1.19,4.35,2.37.92,1.06,1.45,2.77,1.45,4.88v6.86c0,2.37-.92,4.22-2.9,5.54-1.98,1.32-4.48,1.98-7.39,1.98l12.79,17.8h-7.39l-11.47-17.01h-5.8v17.01h-5.93v-40.09h-.13Zm13.85,18.99c2.9,0,5.14-.4,6.33-1.06,1.32-.66,1.98-1.98,1.98-3.96v-5.54c0-3.03-2.64-4.48-7.78-4.48h-8.44v15.04h7.91Z"
                    />
                    <path
                        className="cls-1-sydea"
                        d="m594.95,249.67v100.63c0,18.73-3.69,33.37-11.08,43.66-7.39,10.29-19.52,17.54-36.27,21.76-16.75,4.22-39.96,6.33-69.77,6.33h-116.85v-90.74l57.5-87.71v136.11h56.71c16.22,0,28.62-.66,36.8-2.11,8.31-1.45,14.24-4.09,17.94-8.18,3.69-4.09,5.54-10.42,5.54-19.12v-101.82c0-11.74-4.62-19.92-13.85-24.66-8.7-4.48-22.29-6.73-40.62-6.99h-1.06l-93.9.13-64.36,105.12v99.97h-59.48v-99.18l-64.36-106.43c-41.28.13-90.74.53-101.16.53-20.05,0,1.98.13-9.76.13-16.09,0-27.3,13.19-27.3,20.84v13.85c0,12.93,10.95,19.52,32.84,19.52h50.25c28.22,0,48.67,5.14,61.33,15.69,12.66,10.42,19.12,26.25,19.12,47.61v16.35c0,29.54-5.54,47.35-21.37,58.82-14.24,10.16-34.82,12.13-48.67,12.13-12.53,0-18.86.92-36.93.66-21.76-.13-75.04.4-116.06-.13v-39.57c42.07,0,102.21.13,118.04.13,28.49,0,45.5,1.06,45.5-25.59v-15.96c0-8.18-2.37-14.38-7.12-18.73-4.75-4.35-13.19-6.46-25.32-6.46h-49.59C27.17,316.27,0,294.64,0,251.38v-18.33c0-21.63,8.97-37.46,27.04-47.61,18.07-10.16,18.86-10.55,58.03-10.55h87.57l-.13-.13h67.53l52.23,96.81,51.83-96.81h133.47c8.18,0,15.83.13,23.08.53,18.33.92,33.5,2.9,45.63,6.07,16.75,4.48,29.15,12.13,36.93,23.08,7.78,10.81,11.74,25.85,11.74,45.24Z"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="756.78 372.46 756.78 422.05 611.57 422.05 611.57 336.85 661.42 336.85 661.42 372.46 756.78 372.46"
                    />
                    <path
                        className="cls-1-sydea"
                        d="m788.83,336.85l-31.52,85.07h58.69l28.49-85.07h-55.66Zm190.98,0h-58.03l28.49,85.07h60.27l-30.73-85.07Zm-58.56-162.36h-72.41l-44.31,120.02h54.34l23.61-70.43h1.45l23.61,70.43h57.11l-43.39-120.02Z"
                    />
                    <polygon
                        className="cls-1-sydea"
                        points="757.31 174.49 757.31 224.08 661.42 224.08 661.42 294.51 611.57 294.51 611.57 174.49 757.31 174.49"
                    />
                    <polygon
                        className="cls-2-sydea"
                        points="661.42 336.85 661.42 294.51 756.91 294.51 756.91 238.99 788.96 336.85 661.42 336.85"
                    />
                    <polygon
                        className="cls-2-sydea"
                        points="1018.18 336.85 844.62 336.85 858.86 294.51 1002.88 294.51 1018.18 336.85"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m192.13,77.68v32.1h-5.86V42.18c0-4.8,0-9.96-.12-14.41h5.74c.23,2.23.23,5.74.23,10.54,3.63-7.03,10.08-11.95,20.62-11.95,13.82,0,23.43,11.6,23.43,29.17,0,21.09-11.25,32.22-25.42,32.22-10.31,0-15.7-4.57-18.63-10.08Zm37.84-21.91c0-13.59-6.56-23.78-18.16-23.78-14.18,0-20.03,8.9-20.03,24.84s4.57,25.42,19.21,25.42c12.18,0,18.98-10.31,18.98-26.48Z"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m298.98,56.94c0,16.28-9.02,30.81-26.48,30.81-15.82,0-25.66-13-25.66-30.69,0-16.75,9.26-30.7,26.24-30.7,15.58,0,25.89,12.18,25.89,30.58Zm-45.93.12c0,13.94,7.5,25.19,19.92,25.19s19.8-10.43,19.8-25.19c0-13.82-7.03-25.19-20.03-25.19s-19.68,11.13-19.68,25.19Z"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m313.27,27.77c8.67,30.23,13.71,47.57,14.64,52.72h.12c.94-4.69,6.79-23.08,15.93-52.72h6.33c10.9,36.2,14.18,46.63,15.23,51.2h.12c1.64-6.44,4.33-15.23,15.11-51.2h6.21l-18.51,58.58h-6.44c-6.33-20.74-13.94-46.16-15.11-51.55h-.12c-1.17,5.74-7.85,26.71-15.46,51.55h-7.03l-17.22-58.58h6.21Z"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m400.33,57.76c.12,15.11,7.73,24.49,18.98,24.49s14.76-6.21,16.87-11.01h5.98c-2.46,7.73-8.67,16.52-23.08,16.52-17.81,0-24.72-15.35-24.72-30.23,0-16.75,8.32-31.16,25.42-31.16,18.04,0,23.78,15.58,23.78,26.59,0,1.64,0,3.28-.12,4.8h-43.11Zm37.14-5.15c-.12-11.6-6.44-20.74-17.92-20.74-12.07,0-17.57,8.44-18.86,20.74h36.79Z"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m457.85,43.7c0-9.84-.12-14.06-.12-15.93h5.74c.12,1.87.23,5.62.23,13.12,3.4-8.79,10.9-14.06,20.39-14.53v6.33c-12.18.82-20.39,9.26-20.39,23.2v30.46h-5.86v-42.65Z"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m496.17,57.76c.12,15.11,7.73,24.49,18.98,24.49s14.76-6.21,16.87-11.01h5.97c-2.46,7.73-8.67,16.52-23.08,16.52-17.81,0-24.72-15.35-24.72-30.23,0-16.75,8.32-31.16,25.42-31.16,18.04,0,23.78,15.58,23.78,26.59,0,1.64,0,3.28-.12,4.8h-43.11Zm37.14-5.15c-.12-11.6-6.44-20.74-17.93-20.74-12.07,0-17.57,8.44-18.86,20.74h36.79Z"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m599.5,0v69.47c0,5.62,0,11.01.12,16.87h-5.62c-.23-1.41-.35-6.91-.35-9.84-2.93,7.15-9.49,11.25-19.68,11.25-15.23,0-24.37-12.42-24.37-29.99s9.96-31.4,25.89-31.4c11.72,0,16.4,5.04,18.16,8.79V0h5.86Zm-43.7,57.52c0,16.05,8.32,24.72,18.39,24.72,15.46,0,19.68-10.66,19.68-26.59,0-15.11-3.63-23.78-18.63-23.78-11.6,0-19.45,9.26-19.45,25.66Z"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m649.18,0h5.86v37.61c2.81-5.86,8.55-11.25,20.03-11.25,13.82,0,24.02,11.36,24.02,29.41s-9.14,31.98-25.54,31.98c-9.84,0-15.46-3.98-18.51-10.66,0,3.75-.12,7.62-.23,9.26h-5.74c.12-6.68.12-13.36.12-20.03V0Zm43.7,55.88c0-14.64-7.26-24.02-18.39-24.02-14.64,0-19.8,9.96-19.8,25.31,0,13.82,4.57,25.07,19.1,25.07,11.83,0,19.1-9.84,19.1-26.36Z"
                    />
                    <path
                        className="cls-3-sydea"
                        d="m713.15,27.77c11.83,33.51,16.99,47.33,18.98,53.66h.12c2.34-7.15,7.03-20.74,18.39-53.66h6.09l-22.03,61.98c-6.21,17.34-10.43,21.21-20.97,21.21-2.11,0-3.51-.12-5.39-.35v-5.86c1.76.23,3.4.35,5.27.35,8.43,0,11.13-4.1,15.46-16.17l-22.38-61.16h6.44Z"
                    />
                    </svg>
                    </a>
                </div>

            </div>
        </footer>
    )
};