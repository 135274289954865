import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/home/home';
import { Sponsorship } from './pages/sponsorship/sponsorship';
import { Amplify } from 'aws-amplify';
import { Profile } from './pages/profile/profile';

function App() {

  Amplify.configure({
    Auth: {
      Cognito: {
        //  Amazon Cognito User Pool ID
        userPoolId: 'eu-west-1_urUAe65Bz',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: '6404s550sfscr9ikgt6lg7cq7j',
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
        allowGuestAccess: true,
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'link', // 'code' | 'link'
        loginWith: {
          // OPTIONAL - Hosted UI configuration
          oauth: {
            domain: 'login.yawclub.com',
            scopes: [
              'email', 'openid'
              // 'phone',
              // 'email',
              // 'profile',
              // 'openid',
              // 'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: ['http://localhost:3000/profile', 'https://yawclub.com/profile'],
            redirectSignOut: ['http://localhost:3000/profile', 'https://yawclub.com/profile'],
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
          }
        }
      }
    }
  });
  
  // You can get the current config object
  const currentConfig = Amplify.getConfig();

  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="sponsorship" exact element={<Sponsorship />} />

      <Route path="profile" exact element={<Profile />} />
    </Routes>
  );
}

export default App;
