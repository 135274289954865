import React, { useState } from "react";
import './home.scss';
import { TheGame } from '../../components/sections/the-game/the-game';
import { NftCollection } from '../../components/sections/nft-collection/nft-collection';
import { News } from '../../components/sections/news/news';
import { About } from '../../components/about/about';
import Spinner from 'react-bootstrap/Spinner';
import { Menu } from "../../components/menu/menu";

export const Home = () => {
    const [visLoaderIntro, setVisLoaderIntro] = useState('');
    setTimeout(() => {
        setVisLoaderIntro('hidden')
      }, 1000);

    return (
        <>
            <Menu />
            <div className={`layer-intro ${visLoaderIntro}`}>
                <div className="box-loader-intro">
                    <img src={require("../../assets/logo/yawclub_icon.png")} alt="Yaw Club logo" className="logo-loader-intro"></img>
                    <Spinner animation="border" size="lg" variant="warning"/>
                </div>
            </div>
            <div className='section-home bg-about position-relative'>
                <div className='row p-3 m-0'>
                    <div className='col-sm-12 col-lg-6 d-flex flex-column justify-content-center ps-sm-5'>
                        <h1 className='yc-gold-honey fw-bold title-yc'>YawClub</h1>
                        <h2 className='subtitle-yc'>The fantasy game inspired by Formula 1</h2>
                        <div className='d-flex flex-sm-row flex-column align-items-center gap-3 pt-2 pt-sm-5'>
                            <a href='https://play.google.com/store/apps/details?id=com.sydea.fantaf1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' >
                                <img src={require(`../../assets/social/googleplay.png`)} alt="Get it on Google Play" className='img-fluid img-stores' />
                            </a>
                            <a href="https://apps.apple.com/us/app/yawclub/id1633101059?itsct=apps_box_badge&amp;itscg=30200">
                                <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1662681600" alt="Download on the App Store" className='img-fluid img-stores' />
                            </a>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-6 d-flex align-items-center justify-content-center'>
                        <img src={require('../../assets/image/hero-yc_2024.png')} className='img-fluid h-75 hero-image' alt='YawClub App Screen'></img>
                    </div>
                </div>
                <About />
            </div>

            <div id='game' className='section-home p-5'>
                <TheGame />
            </div>

            <div id='news' className='section-home p-5 bg-sec-news'>
                <News />
            </div>

            <img src={require('../../assets/image/megaphone.png')} className='img-fluid w-100' alt='Join the YawClub community'></img>

            <div id='nft' className='section-home p-5 bg-nft-yawks'>
                <NftCollection />
            </div>
            
            <img src={require('../../assets/image/hero.png')} className='img-fluid w-100' alt='YawClub slogan'></img>
        </>
    );
  };