import React, { useEffect, useState } from "react";
import './profile.scss';
import { Amplify } from 'aws-amplify';
import {
    Authenticator,
    ThemeProvider,
    Theme,
    useTheme,
    View,
    Image,
    Heading,
    Text,
    Button,
    useAuthenticator
  } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
import { fetchUserAttributes } from '@aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import { Link } from "react-router-dom";

export const Profile = () => {

  const [profileData, setProfileDate] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    // Hub.listen("auth", (data) => {
    //   const { payload } = data;
    //   onAuthEvent(payload);
    // });
    currentAuthenticatedUser();
  },[])


  async function handleSignInClick(providedUser) {
      signInWithRedirect({  provider: {custom: providedUser}});
  }

  // async function onAuthEvent(payload) {
  //   switch (payload.event) {
  //       case "signIn":
  //         break;
  //   }
  // }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function currentAuthenticatedUser() {
      setProfileDate(null);
      try {
        const userAttributes = await fetchUserAttributes();
        setProfileDate(userAttributes);
      } catch (err) {
      }
    }

    const components = {
        Header() {
          const { tokens } = useTheme();
          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Link to="/">
                <Image
                  alt="YawClub logo"
                  src={require('../../assets/logo/yawclub_icon.png')}
                  width={'50px'}
                />
              </Link>
            </View>
          );
        },
      
        Footer() {
          const { tokens } = useTheme();
          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Text color={tokens.colors.neutral[40]}>YawClub | &copy; All Rights Reserved</Text>
            </View>
          );
        },
      
        SignIn: {
          Header() {
            const { tokens } = useTheme();
            return (
              <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={4}>Sign in</Heading>
            );
          },
          Footer() {
            const { toForgotPassword } = useAuthenticator();
      
            return (
                <>
                <div className="row-btn-sso gap-3">
                  <button className="gsi-material-button w-50" onClick={() => handleSignInClick('ff1.google')}>
                    <div className="gsi-material-button-state"></div>
                    <div className="gsi-material-button-content-wrapper">
                      <div className="gsi-material-button-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{display: "block"}}>
                          <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                          <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                          <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                          <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                      </div>
                      <span className="gsi-material-button-contents">Sign in with Google</span>
                      <span style={{display: "none"}}>Sign in with Google</span>
                    </div>
                  </button>

                  <button className="btn w-50 p-0" onClick={() => handleSignInClick('ff1.apple')}>
                    <img src={require("../../assets/appleid_button@2x.png")} className="w-100"></img>
                  </button>
                </div>

                <br/>

                <View textAlign="center">
                    <Button
                    fontWeight="normal"
                    onClick={toForgotPassword}
                    size="small"
                    variation="link"
                    >
                    Reset Password
                    </Button>
                </View>
              </>
            );
          },
        },
      
        SignUp: {
          Header() {
            const { tokens } = useTheme();
      
            return (
              <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
              >
                Create a new account
              </Heading>
            );
          },
          Footer() {
            const { toSignIn } = useAuthenticator();
      
            return (
              <View textAlign="center">
                <Button
                  fontWeight="normal"
                  onClick={toSignIn}
                  size="small"
                  variation="link"
                >
                  Back to Sign In
                </Button>
              </View>
            );
          },
        },
        ConfirmSignUp: {
          Header() {
            const { tokens } = useTheme();
            return (
              <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
              >
                Confirm Sign Up:
              </Heading>
            );
          },
          // Footer() {
          //   return <Text>Footer Information</Text>;
          // },
        },
        SetupTotp: {
          Header() {
            const { tokens } = useTheme();
            return (
              <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
              >
                Setup:
              </Heading>
            );
          },
          // Footer() {
          //   return <Text>Footer Information</Text>;
          // },
        },
        ConfirmSignIn: {
          Header() {
            const { tokens } = useTheme();
            return (
              <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
              >
                Confirm Sign In:
              </Heading>
            );
          },
          // Footer() {
          //   return <Text>Footer Information</Text>;
          // },
        },
        ForgotPassword: {
          Header() {
            const { tokens } = useTheme();
            return (
              <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
              >
                Forgot your password?
              </Heading>
            );
          },
        //   Footer() {
        //     return <Text>Footer Information</Text>;
        //   },
        },
        ConfirmResetPassword: {
          Header() {
            const { tokens } = useTheme();
            return (
              <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
              >
                Confirm Reset Password:
              </Heading>
            );
          },
          Footer() {
            const { toSignIn } = useAuthenticator();
            return <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">Back to Sign In</Button>;
          },
        },
      };
      
      const formFields = {
        signIn: {
          username: {
            placeholder: 'Enter your email/username',
          },
        },
        signUp: {
            given_name: {
                order: 1,
                isRequired: true,
                label: "Given name*",
              },
              family_name: {
                order: 2,
                isRequired: true,
                label: "Family name*",
              },
              username: {
                order: 3,
                isRequired: true,
                label: "Username*",
              },
              email: {
                order: 4,
                isRequired: true,
                label: "Email*",
              },
              phone_number: {
                order: 5,
                isRequired: false,
                label: "Phone number",
                dialCode: "+39",
              },
              password: {
                order: 6,
                isRequired: true,
                label: "Password*",
              },
              confirm_password: {
                order: 7,
                isRequired: true,
                label: "Confirm password*",
              },
              birthdate: {
                order: 8,
                labelHidden: false,
                isRequired: false,
                label: "Birthdate",
              }
        },
        forceNewPassword: {
          password: {
            placeholder: 'Enter your Password:',
          },
        },
        forgotPassword: {
          username: {
            placeholder: 'Enter your email:',
          },
        },
        confirmResetPassword: {
          confirmation_code: {
            placeholder: 'Enter your Confirmation Code:',
            label: 'New Label',
            isRequired: false,
          },
          confirm_password: {
            placeholder: 'Enter your Password Please:',
          },
        },
        setupTotp: {
          QR: {
            totpIssuer: 'test issuer',
            totpUsername: 'amplify_qr_test_user',
          },
          confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
          },
        },
        confirmSignIn: {
          confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
          },
        },
      };

      const [item, setItem] = useState({ kindOfStand: "other", another: "another" });

      const { kindOfStand } = item;
    
      const handleChange = e => {
        e.persist();
        console.log(e.target.value);
    
        setItem(prevState => ({
          ...prevState,
          kindOfStand: e.target.value
        }));
      };
    
    const onclickDeleteProfile = async () => {
      try {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        const token = accessToken.toString(); 
        if(!token){
          return;
        }

        const requestOptions = {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${token}` },
          body: JSON.stringify({ deletion_reason: kindOfStand })
        };

        const response = await fetch('https://apiv3.yawclub.com/v2/profilo', requestOptions);

        if(response.ok){
          handleClose();
          await signOut();
        }
      } catch (error) {}
    }

    return (
        <Authenticator 
            formFields={formFields} components={components}
            signUpAttributes={['given_name', 'family_name', 'email', 'birthdate','phone_number']}
            hideSignUp={true}>
            {({ signOut, user }) => (
              <main className="p-5" style={{height:'80vh'}}>
                <div className="d-flex align-items-start justify-content-between">
                  <div>
                    <h2>Hello {user.username}</h2>
                    <br/>
                    {profileData?.email && <p><b>Email:</b> {profileData?.email}</p>}
                    {profileData?.family_name && <p><b>Family name:</b> {profileData?.family_name}</p>}
                    {profileData?.given_name && <p><b>Given name:</b> {profileData?.given_name}</p>}
                  </div>
                  <div>
                    <button onClick={signOut} className="h-auto btn-logout px-3 py-2 fw-bold d-flex align-items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 -0.5 25 25" fill="none">
                          <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z" fill="#f7f7f7"/>
                      </svg>
                      Sign out
                    </button>
                  </div>
                </div>
                <br/>
                <br/>
                <p>To request the deletion of the account and associated data, use the <i>Delete profile</i> button.</p>
                <p>This action is irreversible, once the deletion of the profile and associated data is confirmed, the action cannot be undone.</p>
                <br/>

                <button onClick={handleShow} className="btn-delete-profile px-3 py-2 fw-bold d-flex align-items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 1024 1024"><path fill="#f7f7f7" d="M352 192V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64H96a32 32 0 0 1 0-64h256zm64 0h192v-64H416v64zM192 960a32 32 0 0 1-32-32V256h704v672a32 32 0 0 1-32 32H192zm224-192a32 32 0 0 0 32-32V416a32 32 0 0 0-64 0v320a32 32 0 0 0 32 32zm192 0a32 32 0 0 0 32-32V416a32 32 0 0 0-64 0v320a32 32 0 0 0 32 32z"/></svg>
                  Delete Profile
                </button>
                <Modal show={show} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton>
                    <Modal.Title>
                      <p className="modal-title fw-bold">Delete profile</p>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="modal-title fw-bold text-uppercase">Be careful, this action is irreversible.</p>
                      <br/>
                      <p className="modal-title">Leave feedback on why you want to delete your profile?</p>
                      <br/>
                      <Form.Group controlId="kindOfStand">
                          <Form.Check
                            value="noLike"
                            type="radio"
                            aria-label="I don't like the game"
                            label="I don't like the game"
                            onChange={handleChange}
                            checked={kindOfStand === "noLike"}
                            className="label-radio"
                          />
                          <Form.Check
                            value="noGoodPlayer"
                            type="radio"
                            aria-label="I'm not good at playing"
                            label="I'm not good at playing"
                            onChange={handleChange}
                            checked={kindOfStand === "noGoodPlayer"}
                            className="label-radio"
                          />
                          <Form.Check
                            value="noInterest"
                            type="radio"
                            aria-label="It's not in my interests"
                            label="It's not in my interests"
                            onChange={handleChange}
                            checked={kindOfStand === "noInterest"}
                            className="label-radio"
                          />
                          <Form.Check
                            value="other"
                            type="radio"
                            aria-label="Other"
                            label="Other"
                            onChange={handleChange}
                            checked={kindOfStand === "other"}
                            className="label-radio"
                          />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex gap-3">
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <button variant="primary" className="btn-delete-profile px-3 py-2 fw-bold d-flex align-items-center gap-2" onClick={onclickDeleteProfile}>Delete Profile</button>
                        </div>
                    </Modal.Footer>
                </Modal>
              </main>
            )}
        </Authenticator>
    );
  };